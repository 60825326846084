import React, { useState } from "react";
import { feedbackFormUrl, supportUrl } from "../../config/BaseUrl";
import { Constants } from "../../config/Constants";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import "./Support.scss";
import { Box, Container } from "@mui/material";
import { FullScreenLoader } from "../Loader/Loader";
import "../../components/HomeCard/HomeCard.scss";

const SupportLayout = () => {
  const [open, setOpen] = useState(false);
  const handleWhatsappClick = () => {
    setOpen(true);
    setTimeout(() => {
      const phoneNumber = "+919971139564"; // Replace with the phone number you want to redirect to

      // Check if the user is using a mobile device
      const isMobile = /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);

      let redirectUrl = "";
      if (isMobile) {
        // Use the WhatsApp app
        redirectUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}`;
      } else {
        // Use the WhatsApp web app
        redirectUrl = `https://web.whatsapp.com/send?phone=${phoneNumber}`;
      }

      window.open(redirectUrl, "_blank");
      setOpen(false);
    }, 1000);
  };
  return (
    <Container maxWidth="xl" sx={{ width: "97%", py: 3 }}>
      <div className="homeCardMain supportLayoutMain">
        <div className="supportCardContent">
          <p className="homeCardContentText">
            If you have any support query, please connect with us by filling the below form
          </p>
          <div
            dangerouslySetInnerHTML={{
              __html: `<iframe title='Feedback Form' src=${feedbackFormUrl} scrolling='no' height='530px' width='100%' frameBorder='0'/>`,
            }}
          />
          <p className="homeCardContentText supportText">
            Or please email your query to :{" "}
            <b className="linkText">
              <a href={`mailto:${supportUrl}`} style={{ color: "#0F82AF" }}>
                {supportUrl}
              </a>{" "}
            </b>
          </p>
          <Box className="whatsAppContainer">
            <WhatsAppIcon onClick={handleWhatsappClick} className="whatsappIcon" />{" "}
            <Box sx={{ color: "black" }}>
              Message Your Query on WhatsApp{" "}
              <span onClick={handleWhatsappClick} className="whatsappNumber">
                +91-9971139564
              </span>
            </Box>
          </Box>
          <p className="homeCardContentText">
            Our support staff will respond to your query as soon as possible.
          </p>
        </div>
        <img
          className="supportImage"
          src={`${Constants.imgPath}/graphic-support.png`}
          alt="support_img"
        />
        <FullScreenLoader open={open} />
      </div>
    </Container>
  );
};

export default SupportLayout;
