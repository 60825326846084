import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import Cropper from "react-easy-crop";
import Slider from "@mui/material/Slider";
import Button from "@mui/material/Button";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { cloudfrontUrl } from "../../config/BaseUrl";
import "../InputBox/InputBox.scss";
// import "../courses/CreateBlockModal/CreateBlocks/AddContainer/AddContainer.scss";

const ImageUploader = ({
  onCropped,
  initialImage,
  title,
  className,
  accept,
  icon,
  targetWidth,
  targetHeight,
  modaltype,
}) => {
  const [imageSrc, setImageSrc] = useState(initialImage);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [resizedImage, setResizedImage] = useState(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState(
    initialImage
      ? modaltype === "certificate"
        ? initialImage
        : `${cloudfrontUrl}${initialImage}`
      : null,
  );

  const [errorMessage, setErrorMessage] = useState("");

  const targetAspectRatio = targetWidth / targetHeight;

  useEffect(() => {
    if (initialImage) {
      if (initialImage) {
        setCroppedImageUrl(
          modaltype === "certificate" ? initialImage : `${cloudfrontUrl}${initialImage}`,
        );
      }
    }
  }, [initialImage]);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const resizeImage = (img) => {
    const aspectRatio = img.width / img.height;
    let newWidth = targetWidth;
    let newHeight = targetHeight;

    if (aspectRatio > targetAspectRatio) {
      newHeight = targetHeight;
      newWidth = targetHeight * aspectRatio;
    } else {
      newWidth = targetWidth;
      newHeight = targetWidth / aspectRatio;
    }

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = newWidth;
    canvas.height = newHeight;
    ctx.drawImage(img, 0, 0, newWidth, newHeight);
    return canvas.toDataURL();
  };

  const handleFileChange = (event) => {
    console.log(event.target.files[0], "FILE");

    setCroppedImageUrl(null);
    setImageSrc(null);
    setErrorMessage("");
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result;
        img.onload = () => {
          if (img.width < targetWidth || img.height < targetHeight) {
            setErrorMessage(
              `Please upload an image of at least ${targetWidth}px x ${targetHeight}px.`,
            );
            return;
          }
          const resizedDataUrl = resizeImage(img);
          setImageSrc(resizedDataUrl);
          setResizedImage(resizedDataUrl);
        };
      };
    }
  };

  const getCroppedImg = useCallback(async () => {
    if (!resizedImage || !croppedAreaPixels) return null;
    const image = new Image();
    image.src = resizedImage;
    await new Promise((resolve) => {
      image.onload = resolve;
    });

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = targetWidth;
    canvas.height = targetHeight;

    ctx.drawImage(
      image,
      croppedAreaPixels.x,
      croppedAreaPixels.y,
      croppedAreaPixels.width,
      croppedAreaPixels.height,
      0,
      0,
      targetWidth,
      targetHeight,
    );

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        if (blob) {
          if (modaltype === "certificate") {
            const file = new File([blob], "croppedImage.png", { type: "image/png" });
            resolve(file);
          } else {
            const croppedImageUrl = URL.createObjectURL(blob);
            resolve(croppedImageUrl);
          }
        }
      }, "image/png");
    });
  }, [resizedImage, croppedAreaPixels]);

  const handleSave = async () => {
    const croppedUrl = await getCroppedImg();
    if (croppedUrl) {
      setCroppedImageUrl(croppedUrl);
      console.log(modaltype, "TYPE");
      if (modaltype === "certificate") {
        onCropped(croppedUrl);
      } else {
        const blob = await fetch(croppedUrl).then((r) => r.blob());
        const formData = new FormData();
        formData.append("image", blob);

        onCropped(formData);
      }
      //  URL.revokeObjectURL(croppedUrl); // Clean up the object URL to avoid memory leaks
    }
  };

  return (
    <div className="inputFile">
      {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
      {imageSrc && !croppedImageUrl && !errorMessage ? (
        <>
          <div
            style={{
              position: "relative",
              width: "100%",
              height: "300px", // Set a max height for the Cropper container
              maxHeight: "50vh", // Adjust based on available screen height
            }}
          >
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              aspect={targetAspectRatio}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              className="file-upload"
              onCropComplete={onCropComplete}
              style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
            />
          </div>
          <Slider value={zoom} min={1} max={3} step={0.1} onChange={(e, zoom) => setZoom(zoom)} />
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        </>
      ) : croppedImageUrl ? (
        <>
          <img src={croppedImageUrl} alt={title} style={{ width: "100%", height: "250px" }} />
          <Button variant="contained" component="label">
            Change Image <FileUploadOutlinedIcon style={{ marginLeft: "8px" }} />
            <input type="file" hidden onChange={handleFileChange} />
          </Button>
        </>
      ) : (
        <>
          <label htmlFor={title} className={`custom-file-upload ${className}`}>
            {icon && <FileUploadOutlinedIcon />} <span>{title}</span>
          </label>
          <input
            id={title}
            type="file"
            accept={accept}
            onChange={handleFileChange}
            className="file-upload"
          />
        </>
      )}
    </div>
  );
};

ImageUploader.propTypes = {
  onCropped: PropTypes.func.isRequired,
  initialImage: PropTypes.string,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  accept: PropTypes.string,
  icon: PropTypes.bool,
  targetWidth: PropTypes.number,
  targetHeight: PropTypes.number,
};

export default ImageUploader;
