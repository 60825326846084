import React, { useEffect, useRef, useState } from "react";
import HeaderContainer from "../../HeaderContainer/HeaderContainer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useOutletContext } from "react-router-dom";
import { ParagraphText } from "../../Typography";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import { GET, POST } from "../../../config/Api";
import { Constants } from "../../../config/Constants";
import { useQueryParams } from "../../../utils";
import CreateBlockModal from "../CreateBlockModal/CreateBlockModal";
import NothingHere from "../../NothingHere/NothingHere";
import DiscussionView from "../courseViews/DiscussionView/DiscussionView";
import CarosalBlockView from "../courseViews/CarosalBlockView/CarosalBlockView";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import ContainerView from "../courseViews/ContainerView/ContainerView";
import ComicView from "../courseViews/ComicView/ComicView";
import EmbedView from "../courseViews/EmbedView/EmbedView";
import ListView from "../courseViews/ListView/ListView";
import ResponseView from "../courseViews/ResponseView/ResponseView";
import GridView from "../courseViews/GridView/GridView";
import MatchView from "../courseViews/MatchView/MatchView";
import FillView from "../courseViews/FillView/FillView";
import MCQView from "../courseViews/MCQView/MCQView";
import GifView from "../courseViews/GifView/GifView";
import LinkView from "../courseViews/LinkView/LinkView";
import { FileView } from "../courseViews/FileView/FileView";
import { VideoView } from "../courseViews/VideoView/VideoView";
import { AudioView } from "../courseViews/AudioView/AudioView";
import ImageView from "../courseViews/ImageView/ImageView";
import ToggleList from "../courseViews/ToggleListView/ToggleList";
import DividerView from "../courseViews/DividerView/DividerView";
import ButtonView from "../courseViews/ButtonView/ButtonView";
import TextViews from "../courseViews/TextView/TextView";
import { CourseDetailsSkeleton } from "../../Loader/Loader";
import EditTheme from "../EditBlock/EditTheme";
import EditBlock from "../EditBlock/EditBlock";
import { logoutAction, saveCourseDetailAction } from "../../../redux/action";
import NativeEmbedView from "../courseViews/NativeEmbedView/NativeEmbedView";
import ImportCourseJSON from "../ImportCourseJSON/ImportCourseJSON";
import ScormView from "../courseViews/ScormView/ScormView";
import { ToastNotify } from "../../SnackBar/ToastNotify";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SurveyMonkeyView from "../courseViews/SurveyMonkeyView/SurveyMonkeyViews";

const CreateBlockContainer = () => {
  const [containerDetails, setContainerDetails] = useState([]);
  const [moduleDetails, setModuleDetails] = useState({});
  const [openImportDialog, setOpenImportDialog] = useState(false);

  const [blockUserActivities, setBlockUserActivities] = useState([]);
  const [blockModalOpen, setBlockModalOpen] = useState({
    modalOpen: false,
    currentBlockId: "",
    currentBlockOrder: 1,
    addBelow: false,
  });
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState({
    modalOpen: false,
    block: {},
    themeModalOpen: false,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const { courseOne, setCourseOne } = useOutletContext();

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const toastRef = useRef();
  const [highlightedContainers, setHighlightedContainers] = useState([]);
  let UserData = useSelector((state) => state.userLogin);

  const courseDetails = useSelector((state) => state.savedCourseDetail);
  // const moduleDetails = useSelector((state) => state.savedModuleDetail);
  let rerenderBlocks = useSelector((state) => state.rerenderBlocks);
  const savedCourseDetail = useSelector((state) => state.savedCourseDetail.data);
  const [collapsedContainers, setCollapsedContainers] = useState([]);

  let isLoggedIn = UserData?.data?.isLoggedIn;
  const { completedModulesData } = useOutletContext();

  const { cid, mid } = useQueryParams();

  useEffect(() => {
    if (!savedCourseDetail) {
      (async () => {
        try {
          //get public course details
          const response = await GET(`${Constants.getPublicCourseDetail}/${cid}`, {});
          // setcourseDetails(response?.data?.data);
          dispatch(saveCourseDetailAction(response.data.data));
        } catch (err) {
          navigate("/404");
        }
      })();
    }
  }, [cid, navigate]);

  const highlightParentContainers = (container) => {
    const parentContainerIds = container?.parentContainers || [];
    const highlighted = [];
    parentContainerIds.forEach((parentId) => {
      const foundModule = completedModulesData.modules.find(
        (module) => module.module_id === parentId,
      );
      if ((foundModule && foundModule.status !== "locked") || !foundModule) {
        highlighted.push(parentId);
      }
    });

    setHighlightedContainers(highlighted);
  };

  useEffect(() => {
    if (!isLoggedIn) {
      dispatch(logoutAction());
      navigate("/");
    } else {
      (async () => {
        try {
          setLoading(true);
          //get course container details
          const response = await GET(
            `${Constants.getPublicCourseContainer}/${
              mid || moduleDetails?.id
            }/${cid}?userType=moderator`,
          );
          setLoading(false);
          setContainerDetails(JSON.parse(response?.data?.data));
          setCollapsedContainers(JSON.parse(response?.data?.data));
        } catch (error) {
          navigate("/404");
        }
      })();
    }
  }, [cid, mid, rerenderBlocks, dispatch, isLoggedIn, moduleDetails, navigate]);

  useEffect(() => {
    isLoggedIn &&
      (async () => {
        try {
          //get block user activity
          const response = await GET(
            `${Constants.getPublicCourseBlock}/blockUserActivity/${cid}/${UserData?.data?.user?.id}`,
          );
          setBlockUserActivities(response?.data);
        } catch (error) {
          // navigate("/404");
          console.log(error);
        }
      })();
  }, [cid, isLoggedIn, rerenderBlocks, UserData?.data?.user?.id, navigate]);

  useEffect(() => {
    mid &&
      (async () => {
        try {
          //get container details
          const response = await GET(`${Constants.getPublicCourseBlock}/singleBlock/${mid}`);

          let containerDetails = {
            containerTitle: response?.data?.data?.title,
            containerText: response?.data?.data?.text,
            id: response?.data?.data?._id,
            slug: response?.data?.data?.slug,
          };
          setModuleDetails(containerDetails);
        } catch (error) {
          navigate("/404");
        }
      })();
  }, [mid]);

  const handleCoursePublish = async () => {
    try {
      //publish container content after new block creation
      await POST(`${Constants.course}/publish/${cid}?containerID=${mid}`, {});
      toastRef.current.showToastMessage(
        "New content published successfully.It may take a few seconds to a few minutes for the changes to reflect.",
        "success",
      );
      setCourseOne((prev) => ({ ...prev, published: true }));
    } catch (error) {
      toastRef.current.showToastMessage("Something went wrong.", "error");
    }
  };

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const ExpandBlocks = (containerId) => {
    const expandBlock = collapsedContainers.filter(
      (block) => block?.carousel_container === containerId,
    );
    setContainerDetails((prevCollapsedContainers) => {
      const updatedContainers = [...prevCollapsedContainers, ...expandBlock];
      const uniqueOrderedContainers = collapsedContainers.filter((block) =>
        updatedContainers.some((updatedBlock) => updatedBlock._id === block._id),
      );

      return uniqueOrderedContainers;
    });
  };

  const CollapseBlocks = (containerId) => {
    setContainerDetails(
      containerDetails.filter((block) => block?.carousel_container !== containerId),
    );
  };

  return (
    <div>
      <HeaderContainer>
        {/* <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}> */}
        {/* <Box
          display={"flex"}
          alignItems={{
            xs: "flex-start",
            md: "center",
          }}
          flexWrap={{
            xs: "wrap",
            md: "nowrap",
          }}
          justifyContent={{ md: "space-between" }}
          p={2}
          bgcolor={"#e2e8f0"}
          position={"sticky"}
          top={65}
          zIndex={999}
        > */}
        <Box
          display={"flex"}
          flexDirection={{
            md: "row",
            xs: "column",
            sm: "column",
          }}
          alignItems={"center"}
          justifyContent={{ md: "flex-end" }}
          p={2}
          bgcolor={"#e2e8f0"}
          position={"sticky"}
          top={65}
          zIndex={999}
        >
          <Button
            sx={{ mr: 2, flexShrink: 0 }}
            size="small"
            color="inherit"
            variant="outlined"
            onClick={async (e) => {
              // return;
              e.stopPropagation();
              await navigate(-1);
            }}
          >
            <KeyboardBackspaceIcon />
          </Button>
          <Box width={"100%"} sx={{ mt: { xs: "1rem" } }}>
            {!courseOne?.published && (
              <Alert severity="warning">
                <AlertTitle>
                  Important Note: There are unpublished changes. Please publish to make them visible
                  to learners.
                </AlertTitle>
              </Alert>
            )}
          </Box>
          <Box
            display={"flex"}
            // flexDirection={{
            //   xs: "row",
            //   md: "row",
            // }}
            flexShrink={0}
          >
            <Button
              variant="contained"
              sx={{ mx: { sx: 1, sm: 2 } }}
              onClick={() =>
                setBlockModalOpen((prev) => ({
                  ...prev,
                  modalOpen: true,
                  currentBlockId: "",
                  currentBlockOrder: 1,
                  addBelow: false,
                }))
              }
            >
              Create Block
            </Button>
            <Button
              variant="contained"
              sx={{ mr: 0, mx: 1 }}
              onClick={(e) => {
                e.stopPropagation();
                handleCoursePublish();
              }}
              disabled={courseOne?.published}
            >
              Publish Changes
            </Button>
            <IconButton
              aria-label="menu"
              size="large"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu id="long-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenImportDialog(true);
                }}
              >
                Import Course JSON
              </MenuItem>
            </Menu>
          </Box>
        </Box>
        {/* <Button
            variant="outlined"
            sx={{ mr: 2 }}
            onClick={(e) => {
              e.stopPropagation();
              setOpenImportDialog(true);
            }}
          >
            Import Course JSON
          </Button> */}
        {/* </Box> */}
        {/* </Box> */}
        <div className="breadcrumb-module-container">
          <Typography fontWeight={600} variant="h4" mt={3}>
            {moduleDetails?.containerTitle}
          </Typography>
        </div>
        <ParagraphText>
          <div
            dangerouslySetInnerHTML={{
              __html: moduleDetails?.containerText,
            }}
          ></div>
        </ParagraphText>

        <br />
        <br />
        {loading ? (
          <CourseDetailsSkeleton />
        ) : containerDetails.length > 0 ? (
          <>
            <Grid container spacing={3} columns={100}>
              {containerDetails?.map((item, index) => (
                <React.Fragment key={item._id}>
                  {item?.type === "text" && (
                    <Grid item className="fullwidth" spacing={3} md={item?.size?.width}>
                      <TextViews
                        block={item}
                        theme={item?.theme}
                        art={item?.art}
                        courseDetails={courseDetails.data || courseOne}
                        setblockModOpen={setBlockModalOpen}
                        setmodalOpen={setEditData}
                      />
                    </Grid>
                  )}
                  {item?.type === "button" && (
                    <Grid item className="fullwidth" md={item?.size?.width}>
                      <ButtonView
                        key={item}
                        button={item}
                        courseDetails={courseDetails.data || courseOne}
                        setblockModOpen={setBlockModalOpen}
                        setmodalOpen={setEditData}
                        block={item}
                      />
                    </Grid>
                  )}
                  {item?.type === "divider" && (
                    <Grid item className="fullwidth" md={item?.size?.width}>
                      <DividerView
                        key={item}
                        divider={item}
                        theme={isLoggedIn ? "light" : "dark"}
                        courseDetails={courseDetails.data || courseOne}
                        setblockModOpen={setBlockModalOpen}
                        setmodalOpen={setEditData}
                        block={item}
                      />
                    </Grid>
                  )}
                  {item?.type === "toggle_list" && item?.items?.length > 0 ? (
                    <Grid item spacing={3} className="fullwidth" md={item?.size?.width}>
                      <ToggleList
                        toggle_list={item?.items}
                        theme={item.theme}
                        block={item}
                        courseDetails={courseDetails.data || courseOne}
                        setblockModOpen={setBlockModalOpen}
                        setmodalOpen={setEditData}
                      />
                    </Grid>
                  ) : (
                    ""
                  )}
                  {item?.type === "image" && (
                    <Grid item className="fullwidth" md={item?.size?.width}>
                      <ImageView
                        image={item?.image}
                        key={item?.image}
                        theme={item.theme}
                        block={item}
                        courseDetails={courseDetails.data || courseOne}
                        setblockModOpen={setBlockModalOpen}
                        setmodalOpen={setEditData}
                      />
                    </Grid>
                  )}
                  {item?.type === "audio" && (
                    <Grid item className="fullwidth" spacing={3} md={item?.size?.width}>
                      <AudioView
                        audio={item?.provider}
                        key={item?.provider}
                        block={item}
                        courseDetails={courseDetails.data || courseOne}
                        setblockModOpen={setBlockModalOpen}
                        setmodalOpen={setEditData}
                      />
                    </Grid>
                  )}
                  {item?.type === "video" && (
                    <Grid item className="fullwidth" md={item?.size?.width}>
                      <VideoView
                        video={item}
                        courseDetails={courseDetails.data || courseOne}
                        setblockModOpen={setBlockModalOpen}
                        setmodalOpen={setEditData}
                        block={item}
                      />
                    </Grid>
                  )}
                  {item?.type === "file" && (
                    <Grid item className="fullwidth" md={item?.size?.width}>
                      <FileView
                        file={item?.file}
                        data={item}
                        courseDetails={courseDetails.data || courseOne}
                        setblockModOpen={setBlockModalOpen}
                        setmodalOpen={setEditData}
                        block={item}
                      />
                    </Grid>
                  )}
                  {item?.type === "link" && (
                    <Grid item className="fullwidth" spacing={3} md={item?.size?.width}>
                      <LinkView
                        link={item}
                        courseDetails={courseDetails.data || courseOne}
                        setblockModOpen={setBlockModalOpen}
                        setmodalOpen={setEditData}
                        block={item}
                      />
                    </Grid>
                  )}
                  {item?.type === "gif" && (
                    <Grid item className="fullwidth" spacing={3} md={item?.size?.width}>
                      <GifView
                        gif={item?.gif}
                        block={item}
                        courseDetails={courseDetails.data || courseOne}
                        setblockModOpen={setBlockModalOpen}
                        setmodalOpen={setEditData}
                      />
                    </Grid>
                  )}
                  {item?.type === "mcq" && (
                    <Grid item className="fullwidth" md={item?.size?.width}>
                      <MCQView
                        mcq={item}
                        mcqs={item.mcqs}
                        blockUserActivities={blockUserActivities}
                        courseDetails={courseDetails.data || courseOne}
                        setblockModOpen={setBlockModalOpen}
                        setmodalOpen={setEditData}
                        block={item}
                      />
                    </Grid>
                  )}
                  {item?.type === "survey-monkey" && (
                    <Grid item className="fullwidth" md={item?.size?.width}>
                      <SurveyMonkeyView
                        block={item}
                        courseDetails={courseDetails.data || courseOne}
                        setblockModOpen={setBlockModalOpen}
                        setmodalOpen={setEditData}
                      />
                    </Grid>
                  )}
                  {item?.type === "scorm" && (
                    <Grid item className="fullwidth" md={item?.size?.width}>
                      <ScormView
                        scorm={item}
                        blockUserActivities={blockUserActivities}
                        courseDetails={courseDetails.data || courseOne}
                        setblockModOpen={setBlockModalOpen}
                        setmodalOpen={setEditData}
                        block={item}
                      />
                    </Grid>
                  )}
                  {item?.type === "fill" && (
                    <Grid item className="fullwidth" md={item?.size?.width}>
                      <FillView
                        fill={item}
                        blockUserActivities={blockUserActivities}
                        courseDetails={courseDetails.data || courseOne}
                        setblockModOpen={setBlockModalOpen}
                        setmodalOpen={setEditData}
                        block={item}
                      />
                    </Grid>
                  )}
                  {item?.type === "match" && (
                    <Grid item className="fullwidth" md={item?.size?.width}>
                      <MatchView
                        match={item}
                        blockUserActivities={blockUserActivities}
                        courseDetails={courseDetails.data || courseOne}
                        setblockModOpen={setBlockModalOpen}
                        setmodalOpen={setEditData}
                        block={item}
                      />
                    </Grid>
                  )}
                  {item?.type === "grid" && (
                    <Grid item className="fullwidth" md={item?.size?.width}>
                      <GridView
                        grid={item}
                        courseDetails={courseDetails.data || courseOne}
                        setblockModOpen={setBlockModalOpen}
                        setmodalOpen={setEditData}
                        block={item}
                      />
                    </Grid>
                  )}

                  {item?.type === "carousel_container" && (
                    <Grid item className="fullwidth" md={item?.size?.width}>
                      <CarosalBlockView
                        response={item}
                        art={item?.art}
                        blockUserActivities={blockUserActivities}
                        courseDetails={courseDetails.data || courseOne}
                        setblockModOpen={setBlockModalOpen}
                        setmodalOpen={setEditData}
                        block={item}
                        ExpandBlocks={ExpandBlocks}
                        CollapseBlocks={CollapseBlocks}
                      />
                    </Grid>
                  )}

                  {item?.type === "response" && (
                    <Grid item className="fullwidth" md={item?.size?.width}>
                      <ResponseView
                        response={item}
                        art={item?.art}
                        blockUserActivities={blockUserActivities}
                        courseDetails={courseDetails.data || courseOne}
                        setblockModOpen={setBlockModalOpen}
                        setmodalOpen={setEditData}
                        block={item}
                      />
                    </Grid>
                  )}
                  {item?.type === "list" && (
                    <Grid item className="fullwidth" md={item?.size?.width}>
                      <ListView
                        list={item}
                        courseDetails={courseDetails.data || courseOne}
                        setblockModOpen={setBlockModalOpen}
                        setmodalOpen={setEditData}
                        block={item}
                      />
                    </Grid>
                  )}
                  {item?.type === "embed" && (
                    <Grid item className="fullwidth" md={item?.size?.width}>
                      <EmbedView
                        embed={item}
                        courseDetails={courseDetails.data || courseOne}
                        setblockModOpen={setBlockModalOpen}
                        setmodalOpen={setEditData}
                      />
                    </Grid>
                  )}
                  {item?.type === "native-embed" && (
                    <Grid item className="fullwidth" md={item?.size?.width}>
                      <NativeEmbedView
                        block={item}
                        setblockModOpen={setBlockModalOpen}
                        setmodalOpen={setEditData}
                      />
                    </Grid>
                  )}
                  {/* {item?.comments.length !== 0 && (
                  <Grid
                    item
                    className="fullwidth"
                    spacing={3}
                    lg={item?.size?.width}
                  >
                    <CommentView comments={item.comments} block={item} />
                  </Grid>
                )} */}
                  {item?.type === "comic" && (
                    <Grid item className="fullwidth" spacing={3} md={item?.size?.width}>
                      <ComicView
                        text={item.text}
                        image={item.image}
                        block={item}
                        courseDetails={courseDetails.data || courseOne}
                        setblockModOpen={setBlockModalOpen}
                        setmodalOpen={setEditData}
                      />
                    </Grid>
                  )}
                  {item?.type === "container" && (
                    <Grid item className="fullwidth" md={item?.size?.width}>
                      <ContainerView
                        container={item}
                        courseDetail={containerDetails}
                        setblockModOpen={setBlockModalOpen}
                        setmodalOpen={setEditData}
                        block={item}
                        ishighlighted={highlightedContainers.includes(item?._id)}
                        highlightParentContainers={highlightParentContainers}
                      />
                    </Grid>
                  )}

                  {item?.type === "discussion" && (
                    <Grid item className="fullwidth" md={item?.size?.width}>
                      <DiscussionView
                        discussion={item}
                        courseDetails={courseDetails.data || courseOne}
                        setblockModOpen={setBlockModalOpen}
                        setmodalOpen={setEditData}
                        block={item}
                      />
                    </Grid>
                  )}
                </React.Fragment>
              ))}
            </Grid>
          </>
        ) : (
          <NothingHere />
        )}
        <CreateBlockModal
          blockModalOpen={blockModalOpen}
          setblockModOpen={setBlockModalOpen}
          courseDetails={courseDetails}
          setCourseBlocks={setContainerDetails}
          cid={cid}
        />
        <EditBlock
          modalOpen={editData?.modalOpen}
          setmodalOpen={setEditData}
          block={editData?.block}
          setCourseBlocks={setContainerDetails}
        />
        <EditTheme
          themeModalOpen={editData?.themeModalOpen}
          setThemeModalOpen={setEditData}
          block={editData?.block}
          setCourseBlocks={setContainerDetails}
        />
        <ImportCourseJSON
          setOpenImportDialog={setOpenImportDialog}
          openImportDialog={openImportDialog}
        />
      </HeaderContainer>
      <ToastNotify ref={toastRef} duration={4000} />
    </div>
  );
};

export default CreateBlockContainer;
