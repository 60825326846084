import React from "react";
import { Box, Button, Container, Grid, Typography } from "@mui/material";

import "./HomeLayout.scss";
import EastIcon from "@mui/icons-material/East";
import HomeImage1 from "../../assets/Framerspace_banner_BG.svg";
import HomeImage2 from "../../assets/Home_Image_2.svg";
import HomeImage3 from "../../assets/Home_Image_3.svg";
import HomeImage4 from "../../assets/Home_Image_4.svg";
import HomeImage5 from "../../assets/Home_Image_5.svg";
import HomeImage6 from "../../assets/Re-inventing the way you teach and learn online..svg";
import HomeImage7 from "../../assets/Modular toolkit..svg";
import HomeImage8 from "../../assets/Datacentre..svg";
import HomeImage9 from "../../assets/Contact Us..svg";
import graphImage from "../../assets/Gradient_graph.svg";
import supportImg from "../../assets/FramerSpace Help & Support.svg";

import { useNavigate } from "react-router-dom";
import CardComponent from "../HomeCard/CardComponent";
import { Each } from "../../utils";
import { feedbackFormUrl } from "../../config/BaseUrl";
// import { Constants } from "../../config/Constants";
// import { GET } from "../../config/Api";

const HomeLayout = () => {
  // const [analyticsData, setAnalyticsData] = useState({
  //   certification: 0,
  //   enrollment: 0,
  //   totalEnrollment: 0,
  // });
  const navigate = useNavigate();

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const responses = await Promise.allSettled([
  //         GET(`${Constants.insights}/counts/avgCertifications`),
  //         GET(`${Constants.insights}/counts/avgEnrollment`),
  //         GET(`${Constants.insights}/counts/enrollmentCount`),
  //       ]);

  //       const certificationResponse =
  //         responses[0].status === "fulfilled" ? responses[0].value : { data: { analyticsData: 0 } };
  //       const enrollmentResponse =
  //         responses[1].status === "fulfilled" ? responses[1].value : { data: { analyticsData: 0 } };
  //       const totalEnrollmentResponse =
  //         responses[2].status === "fulfilled" ? responses[2].value : { data: { analyticsData: 0 } };

  //       setAnalyticsData({
  //         certification: certificationResponse.data.analyticsData,
  //         enrollment: enrollmentResponse.data.analyticsData,
  //         totalEnrollment: totalEnrollmentResponse.data.analyticsData,
  //       });
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };

  //   fetchData();
  // }, []);

  const cardData = [
    {
      title: "Re-inventing the way you teach and learn online.",
      description:
        "FramerSpace is an AI-powered digital platform where the curriculum’s content can be implemented in a multi-modal environment, data collected in an open and transparent manner and analysis done with ease and the highest data analytics capabilities in the pursuit of implementing personalised learning plans in a teacher driven setting.",
      image: HomeImage6,
      color: "#E7F8FF",
      textColor: "#244c5d",
    },
    {
      title: "Modular toolkit.",
      description:
        "From the Content creator’s point of view, FramerSpace offers rich content creation abilities including embedding Audio, Rich Text, Video, Journaling, Games (developed using Unity/Phaser) to name a few.<br/>The platform framework design has been made extremely intuitive and powerful to help reduce the learning curve for creators significantly and make it look significantly different than any existing learning system out there - like a comic book of the future!",
      image: HomeImage7,
      color: "#F5EDFF",
      textColor: "#44256b",
    },
    {
      title: "Datacentre.",
      description:
        "Creators are further armed with the real-time Emotion Analysis insights related to course reviews and related discussions and facilitated dialogues. These insights would help creators identify the emotion shift on pertinent topics and identify influencers by studying context-specific trends.<br/>FramerSpace has embedded analytics that helps creators always be mindful of what is working and what is not in terms of the learning outcomes of the respective courses. Predictive analytics within FramerSpace can potentially help creators take pro-active actions, especially in the case of at-risk learners who may be on the verge of dropping out.",

      image: HomeImage8,
      color: "#FFF0EA",
      textColor: "#683a27",
    },
    {
      title: "Contact Us.",
      description:
        "Don’t hesitate to contact us if you wish to integrate FramerSpace as a learning platform, within your school, state, country or a continent!<br/>Drop us a mail for any other query or suggestion at <b><a href='mailto:framerspace@gmail.com'>framerspace@gmail.com</a><b/> <br/>National Information Technology Officer, UNESCO MGIEP<br/><b><a href='mailto:s.mishra@unesco.org'>s.mishra@unesco.org</a></b>",
      image: HomeImage9,
      color: "#E7FFEC",
      textColor: "#245930",
    },
  ];

  return (
    <>
      <Box sx={{ backgroundColor: "#f2f4f8" }}>
        <Container maxWidth="xl" sx={{ width: "91%", py: 2, pl: { xs: "0px" }, pr: { xs: "0px" } }}>
          <Grid container display="flex" justifyContent={"space-between"} alignItems="center">
            <Grid item lg={7} sm={12} xs={12} md={6} padding={"0rem 1rem"}>
              {/* Your content for the left side */}
              <Typography variant="h5" fontWeight={"600"} fontSize={"1.7rem"}>
                Empowering Learners
              </Typography>
              <Typography mt={1} mb={1} fontSize={"18px"}>
                FramerSpace is a co-creation platform that provides building blocks to support the
                creation of online courses and connects learners to peers and creators through
                Artificial Intelligence.
              </Typography>
              <Button
                endIcon={<EastIcon />}
                variant="contained"
                sx={{ textTransform: "none", mt: 1, mb: 1 }}
                onClick={() => navigate("/featured-courses")}
              >
                View Featured Courses
              </Button>
            </Grid>
            <Grid item lg={5} sm={12} xs={12} md={6} display="flex" justifyContent={"end"}>
              {/* Your content for the right side */}
              <img src={HomeImage1} alt="img1" className="bannerImage" />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Container maxWidth="md" sx={{ width: "97%", py: 3 }}>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <Typography
            variant="h4"
            fontWeight={"bold"}
            textAlign={"center"}
            mb={2}
            mt={2}
            color={"#525252"}
          >
            What's in FramerSpace?
          </Typography>
          <Box sx={{ width: "100px", height: "2px", backgroundColor: "#e7a178" }}></Box>
        </Box>
        <Grid container alignItems="center" mt={2}>
          <Grid item lg={5} sm={12} xs={12} md={6}>
            <img src={HomeImage2} alt="" height={"300px"} width={"300px"} />
          </Grid>
          <Grid item lg={7} sm={12} xs={12} md={6}>
            <Typography variant="h5" fontWeight={"600"} mb={2} fontSize={"1.7rem"}>
              Easy, like lego blocks.
            </Typography>
            <Typography fontSize={"18px"}>
              Creation of a course on FramerSpace is simple. Just pick a feature that suits your
              purpose - from the dozens that we have available in our menu bar. Drag, drop and there
              you have it – you’ve created a course!
            </Typography>
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid
            item
            lg={7}
            sm={12}
            xs={12}
            md={6}
            sx={{
              order: {
                sm: 1,
                xs: 2,
              },
            }}
          >
            <Typography variant="h5" fontWeight={"600"} mb={2} fontSize={"1.7rem"}>
              Collaboration at its best.
            </Typography>
            <Typography fontSize={"18px"}>
              Learning happens when it is shared. FramerSpace has been designed in a way in which
              learners collaborate with their peers and course creators.
            </Typography>
          </Grid>
          <Grid
            item
            lg={5}
            sm={12}
            xs={12}
            md={6}
            sx={{
              order: {
                sm: 2,
                xs: 1,
              },
            }}
          >
            <img src={HomeImage3} alt="" height={"300px"} width={"300px"} />
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item lg={5} sm={12} xs={12} md={6}>
            <img src={HomeImage4} alt="" height={"300px"} width={"300px"} />
          </Grid>
          <Grid item lg={7} sm={12} xs={12} md={6}>
            <Typography variant="h5" fontWeight={"600"} mb={2} fontSize={"1.7rem"}>
              Open, yet secure.
            </Typography>
            <Typography fontSize={"18px"}>
              The platform is open source, but we take data privacy very seriously. It is our
              endeavour to ensure that none of your data is leaked or used to serve ads.
            </Typography>
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid
            item
            lg={7}
            sm={12}
            xs={12}
            md={6}
            sx={{
              order: {
                sm: 1,
                xs: 2,
              },
            }}
          >
            <Typography variant="h5" fontWeight={"600"} mb={2} fontSize={"1.7rem"}>
              Analytics and AI-Driven.
            </Typography>
            <Typography fontSize={"18px"}>
              Analytics and AI help you make better decisions at FramerSpace, whether it is in the
              creation of a new course or collaborating to learn in an existing course.
            </Typography>
          </Grid>
          <Grid
            item
            lg={5}
            sm={12}
            xs={12}
            md={6}
            sx={{
              order: {
                sm: 2,
                xs: 1,
              },
            }}
          >
            <img src={HomeImage5} alt="" height={"300px"} width={"300px"} />
          </Grid>
        </Grid>
      </Container>
      <Box
        sx={{
          height: "250px",
          backgroundImage: `url(${graphImage})`, // Use backticks and url()
          backgroundSize: "cover", // Optional: Adjust based on your design
          backgroundPosition: "center",
          width: "100%",
        }}
        className="analyticsDataContainer"
      >
        <Container
          sx={{
            height: "100%",
            display: "grid",
            alignItems: "end",
            paddingBottom: "2rem",
          }}
        >
          <Box
            sx={{
              position: "relative",
              display: {
                sm: "block",
                lg: "grid",
              },
              marginTop: {
                sm: "2rem",
              },
              gridTemplateColumns: "auto auto auto",
            }}
          >
            <Box
              sx={{
                textAlign: "center",
                color: "#fff",
                margin: "auto",
              }}
              className="analyticData"
            >
              {/* {analyticsData?.totalEnrollment ? (
                <>
                  {" "}
                  <Typography variant="h4" fontWeight={"bold"}>
                    {convertToMillion(analyticsData?.totalEnrollment)}
                  </Typography>
                  <Typography variant="body1">Overall Enrollments</Typography>
                </>
              ) : (
                <Skeleton variant="rectangular" width={200} height={60} />
              )} */}
              <Typography variant="h4" fontWeight={"bold"}>
                750,000+
              </Typography>
              <Typography variant="body1">Overall Enrollments</Typography>
            </Box>
            <Box
              sx={{ textAlign: "center", color: "#fff", margin: "auto" }}
              className="analyticData"
            >
              {/* {analyticsData?.enrollment ? (
                <>
                  <Typography variant="h4" fontWeight={"bold"}>
                    {convertToMillion(analyticsData?.enrollment)}
                  </Typography>
                  <Typography variant="body1">Enrollments in last 60 days</Typography>
                </>
              ) : (
                <Skeleton variant="rectangular" width={200} height={60} />
              )} */}
              <Typography variant="h4" fontWeight={"bold"}>
                56,000+
              </Typography>
              <Typography variant="body1">Enrollments in last 60 days</Typography>
            </Box>
            <Box
              sx={{ textAlign: "center", color: "#fff", margin: "auto" }}
              className="analyticData"
            >
              {/* {analyticsData?.certification ? (
                <>
                  <Typography variant="h4" fontWeight={"bold"}>
                    {convertToMillion(analyticsData?.certification)}
                  </Typography>
                  <Typography variant="body1">Certifications in last 60 days</Typography>
                </>
              ) : (
                <Skeleton variant="rectangular" width={200} height={60} />
              )} */}
              <Typography variant="h4" fontWeight={"bold"}>
                40,000+
              </Typography>
              <Typography variant="body1">Certifications in last 60 days</Typography>
            </Box>
          </Box>
        </Container>
      </Box>

      {/* Framerspace features section */}
      <Container maxWidth="xl" sx={{ width: "97%", py: 3 }}>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <Typography
            variant="h4"
            fontWeight={"bold"}
            textAlign={"center"}
            mb={2}
            mt={2}
            color={"#525252"}
          >
            FramerSpace Features
          </Typography>
          <Box sx={{ width: "100px", height: "2px", backgroundColor: "#e7a178" }}></Box>
        </Box>
        {/* <CardComponent /> */}
        <Grid container spacing={4} mt={2}>
          <Each
            of={cardData}
            render={(item, index) => (
              <Grid item key={index} xs={12} sm={12} md={6} lg={6}>
                <CardComponent
                  title={item?.title}
                  description={item?.description}
                  image={item?.image}
                  color={item?.color}
                  textColor={item?.textColor}
                />
              </Grid>
            )}
          />
        </Grid>
        <div id="support-section"></div>
      </Container>

      {/* support section */}
      <Box sx={{ backgroundColor: "#f2f4f8" }}>
        <Container maxWidth="xl" sx={{ width: "97%", py: 3 }}>
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Typography
              variant="h4"
              fontWeight={"bold"}
              textAlign={"center"}
              mb={2}
              mt={2}
              color={"#525252"}
            >
              FramerSpace Help & Support
            </Typography>
            <Box sx={{ width: "100px", height: "2px", backgroundColor: "#e7a178" }}></Box>
          </Box>
          <Grid container spacing={2} mt={2}>
            <Grid item lg={7} md={7} sm={6} xs={12}>
              <Typography variant="body1" mb={2} fontSize={"18px"}>
                If you have any support query, please connect with us by filling the below form
              </Typography>
              <iframe
                title="Feedback Form"
                src={feedbackFormUrl}
                scrolling="no"
                height="530px"
                width="100%"
                frameBorder="0"
              />
            </Grid>
            <Grid
              item
              lg={5}
              md={5}
              sm={6}
              xs={12}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"right"}
            >
              <img src={supportImg} className="support__img" alt="support_img" />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default HomeLayout;
