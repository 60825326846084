import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { GET, POST } from "../../../config/Api";
import { Constants } from "../../../config/Constants";
import { useQueryParams } from "../../../utils";
// import ButtonMain from "../../ButtonMain";
import { CourseDetailsSkeleton } from "../../Loader/Loader";
import CourseBlock from "../CourseBlock";
import CreateBlockModal from "../CreateBlockModal/CreateBlockModal";
import NothingHere from "../../NothingHere/NothingHere";
// import MiniMap from "../MiniMap/MiniMap";
import {
  Box,
  Alert,
  AlertTitle,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import EditBlock from "../EditBlock/EditBlock";
import EditTheme from "../EditBlock/EditTheme";
import { logoutAction, saveCourseDetailAction } from "../../../redux/action";
import KeywordsView from "../../Keywords/KeywordsView";
import ImportCourseJSON from "../ImportCourseJSON/ImportCourseJSON";
import { ToastNotify } from "../../SnackBar/ToastNotify";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const CreateBlock = () => {
  const [blockModalOpen, setBlockModalOpen] = useState({
    modalOpen: false,
    currentBlockId: "",
    currentBlockOrder: 1,
    addBelow: false,
  });
  const [openImportDialog, setOpenImportDialog] = useState(false);

  const [courseBlocks, setCourseBlocks] = useState([]);
  const [courseDetails, setcourseDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState({
    modalOpen: false,
    block: {},
    themeModalOpen: false,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const { courseOne, setCourseOne } = useOutletContext();

  const { cid } = useQueryParams();
  const location = useLocation();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const toastRef = useRef();

  const [collapsedContainers, setCollapsedContainers] = useState([]);
  const rerenderBlocks = useSelector((state) => state.rerenderBlocks);
  const savedCourseDetail = useSelector((state) => state.savedCourseDetail);
  let UserData = useSelector((state) => state.userLogin.data);
  let isLoggedIn = UserData?.isLoggedIn;

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!Object.keys(savedCourseDetail).length > 0) {
      (async () => {
        try {
          //get public course details
          const response = await GET(`${Constants.getPublicCourseDetail}/${cid}`, {});
          setcourseDetails(response?.data?.data);
          dispatch(saveCourseDetailAction(response.data.data));
        } catch (err) {
          navigate("/404");
        }
      })();
    }
  }, [cid, navigate]);

  useEffect(() => {
    if (!isLoggedIn) {
      dispatch(logoutAction());
      navigate("/");
    } else {
      (async () => {
        let body = {};
        const courseroute = location.pathname.startsWith("/course/createblock");
        setLoading(true);
        //get course blocks
        await GET(`${Constants.getPublicCourseBlock}/${cid}?isLearner=${courseroute}`, body)
          .then((response) => {
            setCourseBlocks(JSON.parse(response?.data?.data));
            setCollapsedContainers(JSON.parse(response?.data?.data));
            setLoading(false);
          })
          .catch((error) => {
            navigate("/404");
          });
      })();
    }
  }, [cid, rerenderBlocks, location, navigate, isLoggedIn, dispatch]);

  const handleCoursePublish = async () => {
    try {
      //publish course content after new block creation
      await POST(`${Constants.course}/publish/${cid}`, {});
      setCourseOne((prev) => ({ ...prev, published: true }));
      toastRef.current.showToastMessage(
        "New content published successfully.It may take a few seconds to a few minutes for the changes to reflect.",
        "success",
      );
    } catch (error) {
      toastRef.current.showToastMessage("Something went wrong.", "error");
    }
  };

  const ExpandBlocks = (containerId) => {
    const expandBlock = collapsedContainers.filter(
      (block) => block?.carousel_container === containerId,
    );
    setCourseBlocks((prevCollapsedContainers) => {
      const updatedContainers = [...prevCollapsedContainers, ...expandBlock];
      const uniqueOrderedContainers = collapsedContainers.filter((block) =>
        updatedContainers.some((updatedBlock) => updatedBlock._id === block._id),
      );

      return uniqueOrderedContainers;
    });
  };

  const CollapseBlocks = (containerId) => {
    setCourseBlocks(courseBlocks.filter((block) => block?.carousel_container !== containerId));
  };

  return (
    <>
      <div>
        <Typography
          dangerouslySetInnerHTML={{ __html: courseOne?.tagline }}
          variant="body1"
          mt={2}
          sx={{
            margin: "0px",
            fontSize: "1.14286rem",
            lineHeight: 1.5,
          }}
        ></Typography>
        <KeywordsView keywords={courseOne} />
        <Box
          display={"flex"}
          flexDirection={{
            md: "row",
            xs: "column",
          }}
          alignItems={"center"}
          justifyContent={{ md: "flex-end" }}
          p={2}
          bgcolor={"#e2e8f0"}
          position={"sticky"}
          top={65}
          zIndex={999}
        >
          {!courseOne?.published && (
            <Box width={"100%"}>
              <Alert severity="warning">
                <AlertTitle>
                  Important Note: There are unpublished changes. Please publish to make them visible
                  to learners.
                </AlertTitle>
              </Alert>
            </Box>
          )}
          <Box
            display={"flex"}
            flexShrink={0}
            sx={{
              mt: {
                xs: "1rem",
              },
            }}
          >
            <Button
              variant="contained"
              sx={{ mx: 2 }}
              onClick={() =>
                setBlockModalOpen((prev) => ({
                  ...prev,
                  modalOpen: true,
                  currentBlockId: "",
                  currentBlockOrder: 1,
                  addBelow: false,
                }))
              }
            >
              Create Block
            </Button>
            <Button
              variant="contained"
              sx={{ mr: 2 }}
              onClick={(e) => {
                e.stopPropagation();
                handleCoursePublish();
              }}
              disabled={courseOne?.published}
            >
              Publish Changes
            </Button>
            <IconButton
              aria-label="menu"
              size="large"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu id="long-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenImportDialog(true);
                }}
              >
                Import Course JSON
              </MenuItem>
            </Menu>
          </Box>
        </Box>
        {loading ? (
          <CourseDetailsSkeleton />
        ) : courseBlocks?.length === 0 ? (
          <NothingHere />
        ) : (
          <>
            <CourseBlock
              blockDetails={courseBlocks}
              courseDetails={courseOne}
              setmodalOpen={setEditData}
              setblockModOpen={setBlockModalOpen}
              CollapseBlocks={CollapseBlocks}
              ExpandBlocks={ExpandBlocks}
              courseOne={courseOne}
            />
          </>
        )}
      </div>
      <CreateBlockModal
        blockModalOpen={blockModalOpen}
        setblockModOpen={setBlockModalOpen}
        courseDetails={courseDetails}
        setCourseBlocks={setCourseBlocks}
        cid={cid}
        type="landing"
      />
      <EditBlock
        modalOpen={editData?.modalOpen}
        setmodalOpen={setEditData}
        setCourseBlocks={setCourseBlocks}
        block={editData?.block}
        type="landing"
      />
      <EditTheme
        themeModalOpen={editData?.themeModalOpen}
        setThemeModalOpen={setEditData}
        setCourseBlocks={setCourseBlocks}
        block={editData?.block}
      />
      <ImportCourseJSON
        setOpenImportDialog={setOpenImportDialog}
        openImportDialog={openImportDialog}
      />
      <ToastNotify ref={toastRef} duration={6000} />
    </>
  );
};

export default CreateBlock;
